import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { RouteType } from '@/types/routes'
import helper from './utils/helper/helper'
import { RolePermissions } from './utils/role'

const Dashboard = React.lazy(() => import('./modules/dashboard/views/Main'))
const Signin = React.lazy(() => import('./modules/auth/Signin/views/SigninMain'))
const SiteMenuBanner = React.lazy(() => import('./modules/site-menu/Banner/views/BannerMain'))
const SiteMenuBannerDetail = React.lazy(() => import('./modules/site-menu/Banner/views/BannerDetail'))
const SiteMenuBannerEdit = React.lazy(() => import('./modules/site-menu/Banner/views/BannerEdit'))
const SiteMenuPages = React.lazy(() => import('./modules/site-menu/Pages/views/PagesMain'))
const SiteMenuPagesDetail = React.lazy(() => import('./modules/site-menu/Pages/views/PagesDetail'))
const UsersAdminMain = React.lazy(() => import('./modules/users/Admin/views/AdminMain'))
const UsersAdminEditDetail = React.lazy(() => import('./modules/users/Admin/views/AdminEditDetail'))
const UsersRolesMain = React.lazy(() => import('./modules/users/Roles/views/RolesMain'))
const UsersFacilitatorMain = React.lazy(() => import('./modules/users/Facilitator/views/FacilitatorMain'))
const UsersUserMain = React.lazy(() => import('./modules/users/User/views/UserMain'))
const UsersUserDetail = React.lazy(() => import('./modules/users/User/views/UserDetail'))
const UsersPermissionsList = React.lazy(() => import('./modules/users/Permissions/views/PermissionsList'))
const UsersEditRoles = React.lazy(() => import('./modules/users/Permissions/views/EditRoles'))
const RatingReviewMain = React.lazy(() => import('./modules/users/RatingReview/views/RatingReviewMain'))
const NonExistentPage = React.lazy(() => import('./modules/errors/views/NonExistent'))
const UnauthorizedPage = React.lazy(() => import('./modules/errors/views/Unauthorized'))
const FeaturedCourseList = React.lazy(() => import('./modules/course/FeaturedCourse/views/FeaturedCourseList'))
const FeaturedCourseEdit = React.lazy(() => import('./modules/course/FeaturedCourse/views/FeaturedCourseEdit'))
const CourseListMain = React.lazy(() => import('./modules/course/CourseList/views/CourseListMain'))
const CourseListForm = React.lazy(() => import('./modules/course/CourseList/views/CourseListForm'))
const CourseListDetail = React.lazy(() => import('./modules/course/CourseList/views/CourseListDetail'))
const CourseContentForm = React.lazy(() => import('./modules/course/CourseList/views/CourseContentForm'))
const InviteValidation = React.lazy(() => import('./modules/auth/InviteValidation/views/InviteValidation'))
const VourcherList = React.lazy(() => import('./modules/course/Voucher/views/VoucherList'))
const VourcherDetail = React.lazy(() => import('./modules/course/Voucher/views/VoucherDetail'))
const VourcherForm = React.lazy(() => import('./modules/course/Voucher/views/VoucherForm'))
const TransactionList = React.lazy(() => import('./modules/transaction/views/TransactionList'))
const TransactionDetail = React.lazy(() => import('./modules/transaction/views/TransactionDetail'))
const UsersUserCreate = React.lazy(() => import('./modules/users/User/views/UserCreate'))
const UsersFacilitatorCreate = React.lazy(() => import('./modules/users/Facilitator/views/FacilitatorCreate'))
const UsersFacilitatorDetail = React.lazy(() => import('./modules/users/Facilitator/views/FacilitatorDetail'))
const TaskList = React.lazy(() => import('./modules/course/Task/views/TaskList'))
const TaskDetailInfo = React.lazy(() => import('./modules/course/Task/views/TaskDetailInfo'))
const TaskSubmissions = React.lazy(() => import('./modules/course/Task/views/TaskSubmissions'))
const CertificateList = React.lazy(() => import('./modules/course/Certificate/views/CertificateList'))
const QuizList = React.lazy(() => import('./modules/course/Quiz/views/QuizList'))
const QuizDetailInfo = React.lazy(() => import('./modules/course/Quiz/views/QuizDetailInfo'))
const QuizParticipant = React.lazy(() => import('./modules/course/Quiz/views/QuizParticipant'))
const PlaylistsMain = React.lazy(() => import('./modules/partnerships/Playlists/views/PlaylistsMain'))
const PlaylistsDetail = React.lazy(() => import('./modules/partnerships/Playlists/views/PlaylistsDetail'))
const PlaylistsForm = React.lazy(() => import('./modules/partnerships/Playlists/views/PlaylistsForm'))
const GroupsMain = React.lazy(() => import('./modules/partnerships/Groups/views/GroupsMain'))
const GroupsDetail = React.lazy(() => import('./modules/partnerships/Groups/views/GroupsDetail'))
const GroupsForm = React.lazy(() => import('./modules/partnerships/Groups/views/GroupsForm'))

interface MiddlewareType {
  permission: string
  children: any
}

const MiddlewareRoute = ({ permission, children }: MiddlewareType) => {
  if (!helper.checkPermission(permission)) {
    return <Navigate to="/401" replace />
  }

  return children
}

const AllRoutes = () => {

  const routes: RouteType[] = [
    {
      key: 'dashboard',
      route: '/',
      component: <Dashboard />
    },
    {
      key: 'users',
      collapse: [
        {
          key: 'roles',
          route: 'users/roles',
          component: <UsersRolesMain />
        },
        {
          key: 'admin',
          route: 'users/admin',
          component: <UsersAdminMain />,
          permission: RolePermissions.adminmgmt_view.id
        },
        {
          key: 'admin-edit-detail',
          route: 'users/admin/:serial',
          component: <UsersAdminEditDetail />,
          permission: RolePermissions.adminmgmt_update.id
        },
        {
          key: 'user',
          route: 'users/user',
          component: <UsersUserMain />,
          permission: RolePermissions.usermgmt_view.id
        },
        {
          key: 'user-create',
          route: 'users/user/form',
          component: <UsersUserCreate />
        },
        {
          key: 'user-detail',
          route: 'users/user/:serial',
          component: <UsersUserDetail />
        },
        {
          key: 'facilitator',
          route: 'users/facilitator',
          component: <UsersFacilitatorMain />,
          permission: RolePermissions.facilitatormgmt_view.id
        },
        {
          key: 'facilitator-create',
          route: 'users/facilitator/form',
          component: <UsersFacilitatorCreate />
        },
        {
          key: 'facilitator-detail',
          route: 'users/facilitator/:serial',
          component: <UsersFacilitatorDetail />
        },
        {
          key: 'permissions',
          collapse: [
            {
              key: 'permissions-list',
              route: 'users/permissions',
              component: <UsersPermissionsList />,
              permission: RolePermissions.rolemgmt_view.id
            },
            {
              key: 'permissions-edit-roles',
              route: 'users/permissions/roles',
              component: <UsersEditRoles />,
              permission: RolePermissions.rolemgmt_update.id
            }
          ]
        }
      ]
    },
    {
      key: 'site-menu',
      collapse: [
        {
          key: 'banner',
          collapse: [
            {
              key: 'banner-main',
              route: 'site-menu/banner',
              component: <SiteMenuBanner />,
              permission: RolePermissions.banner_view.id
            },
            {
              key: 'banner-detail',
              route: 'site-menu/banner/:id',
              component: <SiteMenuBannerDetail />,
              permission: RolePermissions.banner_view.id
            },
            {
              key: 'banner-detail',
              route: 'site-menu/banner/:id/edit',
              component: <SiteMenuBannerEdit />,
              permission: RolePermissions.banner_update.id
            }
          ]
        },
        {
          key: 'pages',
          collapse: [
            {
              key: 'pages-main',
              route: 'site-menu/pages',
              component: <SiteMenuPages />,
              permission: 'pages.show'
            },
            {
              key: 'pages-detail',
              route: 'site-menu/pages/:id',
              component: <SiteMenuPagesDetail />,
              permission: 'pages.show'
            }
          ]
        }
      ]
    },
    {
      key: 'course',
      collapse: [
        {
          key: 'course-list',
          route: 'course/course-list',
          component: <CourseListMain />
          // permission: 'banner.show'
        },
        {
          key: 'course-list-form',
          route: 'course/course-list/form',
          component: <CourseListForm />
          // permission: 'banner.show'
        },
        {
          key: 'course-list-form',
          route: 'course/course-list/form/content/:serial',
          component: <CourseContentForm />
          // permission: 'banner.show'
        },
        {
          key: 'course-list-form',
          route: 'course/course-list/form/content',
          component: <CourseContentForm />
          // permission: 'banner.show'
        },
        {
          key: 'course-list-detail',
          route: 'course/course-list/detail/:serial',
          component: <CourseListDetail />
          // permission: 'banner.show'
        },
        {
          key: 'featured-course',
          route: 'course/featured-course',
          component: <FeaturedCourseList />
        },
        {
          key: 'featured-course-edit',
          route: 'course/featured-course/:id',
          component: <FeaturedCourseEdit />
        },
        {
          key: 'certificate',
          route: 'course/certificate',
          component: <CertificateList />
        },
        {
          key: 'course-rating-review',
          route: 'course/rating-review',
          component: <RatingReviewMain />
        },
        {
          key: 'course-vouchers',
          route: 'course/vouchers',
          component: <VourcherList />
        },
        {
          key: 'course-vouchers',
          route: 'course/vouchers/:serial',
          component: <VourcherDetail />
        },
        {
          key: 'course-voucher-form',
          route: 'course/vouchers/form',
          component: <VourcherForm />
        },
        {
          key: 'course-quiz',
          route: 'course/quiz',
          component: <QuizList />
        },
        {
          key: 'course-quiz-detail',
          route: 'course/quiz/:serial',
          component: <QuizDetailInfo />
        },

        {
          key: 'course-quiz-participant',
          route: 'course/quiz/:serial/:contentserial',
          component: <QuizParticipant />
        },
        {
          key: 'course-task',
          route: 'course/task',
          component: <TaskList />
        },
        {
          key: 'course-task-detail',
          route: 'course/task/:serial',
          component: <TaskDetailInfo />
        },
        {
          key: 'course-task-submissions',
          route: 'course/task/:serial/:contentserial',
          component: <TaskSubmissions />
        }
      ]
    },
    {
      key: 'partnerships',
      collapse: [
        {
          key: 'playlists',
          route: 'partnerships/playlists',
          component: <PlaylistsMain />
        },
        {
          key: 'playlists-form',
          route: 'partnerships/playlists/form',
          component: <PlaylistsForm />
        },
        {
          key: 'playlists-detail',
          route: 'partnerships/playlists/detail/:serial',
          component: <PlaylistsDetail />
        },
        {
          key: 'groups',
          route: 'partnerships/groups',
          component: <GroupsMain />
        },
        {
          key: 'groups-form',
          route: 'partnerships/groups/form',
          component: <GroupsForm />
        },
        {
          key: 'groups-detail',
          route: 'partnerships/groups/detail/:serial',
          component: <GroupsDetail />
        }
      ]
    },
    {
      key: 'transaction',
      collapse: [
        {
          key: 'transaction-list',
          route: 'transaction/transaction-list',
          component: <TransactionList />
        },
        {
          key: 'transaction-detail',
          route: 'transaction/transaction-list/:serial',
          component: <TransactionDetail />
        }
      ]
    },
    {
      key: 'signin',
      route: 'signin',
      component: <Signin />
    },
    {
      key: 'error-non-existent',
      route: '404',
      component: <NonExistentPage />
    },
    {
      key: 'error-unauthorized',
      route: '401',
      component: <UnauthorizedPage />
    },
    {
      key: 'invite-token-validation',
      route: 'validate-admin-invitation',
      component: <InviteValidation />
    }
  ]

  const getRoutes: any = (allRoutes: RouteType[]) => {
    return allRoutes?.map((route: RouteType) => {
      if (route.collapse) {
        return getRoutes(route.collapse)
      }

      if (route.route) {
        return <Route
          path={route.route}
          element={route?.permission ?
            <MiddlewareRoute permission={route.permission}>
              {route.component}
            </MiddlewareRoute> :
            route.component
          }
          key={route.key}
        />
      }

      return null
    })
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  )
}

export default AllRoutes
