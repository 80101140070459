import { Action } from '@/types/context'
import { PartnerGroupsDetailType } from '@/types/contracts/partnerships'

export enum PartnerGroupsActionTypes {
  SET_PARTNER_GROUP_DETAIL = 'SET_PARTNER_GROUP_DETAIL',
  SET_PARTNER_GROUP_DETAIL_LOADING = 'SET_PARTNER_GROUP_DETAIL_LOADING',
}

export interface PartnerGroupsState {
  groupDetail?: { data?: PartnerGroupsDetailType | null, isLoading: boolean }
}

const reducer = (state: PartnerGroupsState, action: Action<PartnerGroupsActionTypes>): PartnerGroupsState => {
  switch (action.type) {
    case PartnerGroupsActionTypes.SET_PARTNER_GROUP_DETAIL:
      return {
        ...state,
        groupDetail: {
          data: action.payload,
          isLoading: false
        }
      }
    case PartnerGroupsActionTypes.SET_PARTNER_GROUP_DETAIL_LOADING:
      return {
        ...state,
        groupDetail: {
          ...state.groupDetail,
          isLoading: action?.payload ?? false
        }
      }
    default:
      return state
  }
}

export default reducer
