import { Steps } from 'antd'
import MoleculesMainContent from '../MainContent'
import './Stepper.scss'

interface StepInfoType {
  key: number
  title: string
}

interface PropTypes {
  stepsInformation?: StepInfoType[]
  onChangeStepper?: (n: number) => void
  currentStep: number
}

const { Step } = Steps
const StepperForm = ({
    stepsInformation,
    onChangeStepper,
    currentStep
  }: PropTypes) => {

  return (
    <div className="mb-6">
    <MoleculesMainContent>
      <Steps
        className="steppers-list__step"
        size="small"
        labelPlacement="vertical"
      >
        {stepsInformation?.map((item: StepInfoType) =>
          <Step
            className="steppers-list__step-item"
            key={item.key}
            status={item.key <= currentStep ? 'process' : 'wait'}
            title={item.title}
            onStepClick={onChangeStepper}
          />
        )}
      </Steps>
    </MoleculesMainContent>
  </div>
  )
}

export default StepperForm
