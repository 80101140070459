import { Action } from '@/types/context'
import { PartnerPlaylistDetailType } from '@/types/contracts/partnerships'

export enum PartnerPlaylistsActionTypes {
  SET_PARTNER_PLAYLIST_DETAIL = 'SET_PARTNER_PLAYLIST_DETAIL',
  SET_PARTNER_PLAYLIST_DETAIL_LOADING = 'SET_PARTNER_PLAYLIST_DETAIL_LOADING',
}

export interface PartnerPlaylistsState {
  playlistDetail?: { data?: PartnerPlaylistDetailType | null, isLoading: boolean }
}

const reducer = (state: PartnerPlaylistsState, action: Action<PartnerPlaylistsActionTypes>): PartnerPlaylistsState => {
  switch (action.type) {
    case PartnerPlaylistsActionTypes.SET_PARTNER_PLAYLIST_DETAIL:
      return {
        ...state,
        playlistDetail: {
          data: action.payload,
          isLoading: false
        }
      }
    case PartnerPlaylistsActionTypes.SET_PARTNER_PLAYLIST_DETAIL_LOADING:
      return {
        ...state,
        playlistDetail: {
          ...state.playlistDetail,
          isLoading: action?.payload ?? false
        }
      }
    default:
      return state
  }
}

export default reducer
