import { PartnerPlaylistDataRequestType } from '@/types/contracts/partnerships'
import methods from '../methods'

const API_URL = '/api/admin'

const getPartnerGroups = (params?: string) => methods.GET_API(`${API_URL}/partners/groups?${params}`)
const getPartnerGroupDetail = (serial: string) => methods.GET_API(`${API_URL}/partners/group/${serial}`)
const getPartnerGroupMembers = (serial: string, params: string) => methods.GET_API(`${API_URL}/partners/group/${serial}/members?${params}`)
const getPartnerGroupAllMembers = (emails?: string) => methods.GET_API(`${API_URL}/partners/groups/members?emails=${emails}`)
const deletePartnerGroup = (serial: string) => methods.DELETE_API(`${API_URL}/partners/group/${serial}`)
const upsertPartnerGroup = (data: PartnerPlaylistDataRequestType) =>  methods.POST_API(`${API_URL}/partners/group`, data)

const getPartnerPlaylists = (params?: string) => methods.GET_API(`${API_URL}/partners/playlists?${params}`)
const getPartnerPlaylistDetail = (serial: string) => methods.GET_API(`${API_URL}/partners/playlists/${serial}`)
const upsertPartnerPlaylist = (data: PartnerPlaylistDataRequestType) =>  methods.POST_API(`${API_URL}/partners/playlists`, data)

export default {
  getPartnerGroups,
  getPartnerGroupDetail,
  getPartnerGroupMembers,
  getPartnerGroupAllMembers,
  deletePartnerGroup,
  upsertPartnerGroup,
  getPartnerPlaylistDetail,
  upsertPartnerPlaylist,
  getPartnerPlaylists
}
