import { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import reducer, { PartnerPlaylistsActionTypes, PartnerPlaylistsState } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: PartnerPlaylistsState = {
  playlistDetail: {
    data: null,
    isLoading: false
  }
}

const PartnerPlaylistsContext = createContext<ContextType<PartnerPlaylistsState, Action<PartnerPlaylistsActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const PartnerPlaylistsProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <PartnerPlaylistsContext.Provider value={{ ...state, dispatch }}>
    {children}
  </PartnerPlaylistsContext.Provider>

}

export default PartnerPlaylistsContext
export { PartnerPlaylistsActionTypes }
